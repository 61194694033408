import React from "react";

const Checkbox = ({ label, name, register, error }) => {
  return (
    <div className="flex items-center gap-2">
      <input
        type="checkbox"
        id={name}
        {...register(name)}
        className="w-5 h-5 text-primaryColor border-gray-300 rounded focus:ring-primaryColor"
      />
      <label htmlFor={name} className="text-gray-700">
        {label}
      </label>
      {error && <p className="text-red-500 text-sm">{error}</p>}
    </div>
  );
};

export default Checkbox;
