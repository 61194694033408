import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import InputField from "../../components/fields/TextInput";
import { z } from "zod";
import { db } from "../../firebase";
import { addDoc, collection } from "firebase/firestore";
import { toast } from "react-toastify";
import { logUserActivity } from "../../services/logUserActivity";
import { useNavigate } from "react-router-dom";

// Define the schema for employee input validation
const employeeSchema = z.object({
  name: z
    .string()
    .min(1, { message: "Employee Name is required" })
    .max(30, { message: "Employee Name cannot exceed 30 characters" }),
  position: z
    .string()
    .min(1, { message: "Job Title is required" })
    .max(300, { message: "Job Title cannot exceed 300 characters" }),
  email: z
    .string()
    .email({ message: "Invalid email format" })
    .max(50, { message: "Employee Email cannot exceed 50 characters" }),
  // bankId: z.string().min(1, { message: "Bank selection is required" }),
  // departmentId: z
  //   .string()
  //   .min(1, { message: "Department selection is required" }),
});

const AddEmployee = () => {
  // const [banks, setBanks] = useState([]);
  // const [departments, setDepartments] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(employeeSchema),
  });

  const navigate = useNavigate();

  // Fetch available banks on component mount
  // useEffect(() => {
  //   const fetchBanks = async () => {
  //     try {
  //       const banksCollectionRef = collection(db, "Banks");
  //       const bankSnapshot = await getDocs(banksCollectionRef);
  //       const bankList = bankSnapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }));
  //       setBanks(bankList);
  //     } catch (error) {
  //       console.error("Error fetching banks:", error);
  //     }
  //   };
  //   fetchBanks();
  // }, []);

  // // Fetch departments for the selected bank
  // const fetchDepartments = async (bankId) => {
  //   if (!bankId) return;
  //   try {
  //     const bankDocRef = doc(db, "Banks", bankId);
  //     const departmentsCollectionRef = collection(bankDocRef, "Departments");
  //     const departmentSnapshot = await getDocs(departmentsCollectionRef);
  //     const departmentList = departmentSnapshot.docs.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data(),
  //     }));

  //     setDepartments(departmentList);
  //   } catch (error) {
  //     console.error("Error fetching departments:", error);
  //   }
  // };

  const onSubmit = async (data) => {
    const formattedData = {
      name: data.name,
      position: data.position,
      email: data.email,
      // bankId: data.bankId,
      // departmentId: data.departmentId,
    };

    try {
      const employeesCollectionRef = collection(db, "Employees");
      await addDoc(employeesCollectionRef, formattedData);
      toast("Successfully added employee!");
      logUserActivity("User Added Employee");
      navigate("/employees");
    } catch (error) {
      console.error("Error adding employee document:", error);
      toast.error("Failed to add employee.");
    }
  };

  return (
    <>
      <div>
        <h1 className="font-bold text-xl">Create Employee</h1>
      </div>
      <div className="rounded-lg shadow-lg">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-[90%] mx-auto flex flex-col gap-4 p-10">
            <InputField
              label="Employee Name"
              error={errors.name?.message}
              register={register}
              name="name"
            />
            <InputField
              label="Job Title"
              error={errors.position?.message}
              register={register}
              name="position"
            />
            <InputField
              label="Employee Email"
              error={errors.email?.message}
              register={register}
              name="email"
            />

            {/* <SelectField
              label="Bank"
              options={banks}
              register={register}
              name="bankId"
              error={errors.bankId?.message}
              onChange={(e) => {
                register("bankId").onChange(e);
                fetchDepartments(e.target.value);
              }}
              disabled={!banks.length}
            />

            <SelectField
              label="Department"
              options={departments}
              register={register}
              name="departmentId"
              error={errors.departmentId?.message}
              disabled={!departments.length}
            /> */}

            <button
              type="submit"
              className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6 my-6"
            >
              Add Employee
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddEmployee;
