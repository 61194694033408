import { db } from "../firebase"; // Ensure Firebase is configured correctly
import { collection, addDoc, getDocs, updateDoc, deleteDoc, query, where, orderBy, limit } from "firebase/firestore";
import { ActivityLog } from "../models/ActivityLog";
import { getDeviceInfo } from "../utils/deviceUtils";
import { LOGGING_CONFIG } from "../config/loggingConfig";

// Function to manage log rotation in the GlobalActivityLogs collection
const manageLogRotation = async () => {
  const activityRef = collection(db, "GlobalActivityLogs");
  const q = query(activityRef, orderBy("timestamp"), limit(LOGGING_CONFIG.maxLogSize));

  const querySnapshot = await getDocs(q);

  if (querySnapshot.size >= LOGGING_CONFIG.maxLogSize) {
    // Fetch the oldest logs and delete them
    const logsToDelete = querySnapshot.docs.slice(0, querySnapshot.size - LOGGING_CONFIG.maxLogSize + 1);
    for (const log of logsToDelete) {
      await deleteDoc(log.ref);
    }
  }
};

export const logActivity = async (userId, username, actionType, metadata, details = {}) => {
  try {
    // Ensure that details contains the necessary fields or provide default values
    if (!details.item) {
      details.item = "unknown item"; // Set a default value for missing item
    }
    if (!details.itemId) {
      details.itemId = "unknown item ID"; // Set a default value for missing itemId
    }

    // Generate the activity log message
    const message = generateActivityLog(actionType, { name: username }, details);

    // Create a new log object
    const log = new ActivityLog(userId, username, actionType, metadata, message);
    log.validate();

    // Save the log to the GlobalActivityLogs collection
    const logData = log.toPlainObject();
    const activityRef = collection(db, "GlobalActivityLogs");
    const docRef = await addDoc(activityRef, logData);

    // Add the logId to the details object
    details.logId = docRef.id;

    // Update the log in Firebase with the logId
    await updateDoc(docRef, { logId: docRef.id });

    // Log the logId and message
    console.log(`Log saved with ID: ${docRef.id}`);

  } catch (error) {
    console.error("Error logging activity:", error);
  }
};

export function generateActivityLog(action, user, details) {
  console.log("Details:", details);
  console.log(`Action: ${action}`);

  const logId = details?.logId; // Retrieve logId from details

  switch (action) {
    case "update":
      return `User ${user.name} updated ${logId ? logId : "an item"}`; 
    case "delete":
      return `User ${user.name} deleted ${logId ? logId : "an item"}`;
    case "add":
      return `User ${user.name} added ${logId ? logId : "an item"}`;
    case "view":
      return `User ${user.name} viewed ${logId ? logId : "an item"}`;
    default:
      return `User ${user.name} performed an action: ${action}.`;
  }
}

export const autoLogUserActivity = async (userId, username, action, details = {}) => {
  const { deviceType, os } = getDeviceInfo();
  const metadata = { deviceType, os };

  await logActivity(userId, username, action, metadata, details);
};

// Fetch activities for a user, including logId in details
export const fetchActivitiesForUser = async (userId) => {
  try {
    const activityRef = collection(db, "GlobalActivityLogs");
    const q = query(activityRef, where("userId", "==", userId), orderBy("timestamp", "desc"));

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.log(`No activities found for user: ${userId}`);
      return [];
    }

    const activities = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));

    console.log(`Fetched activities for user: ${userId}`, activities);
    return activities;
  } catch (error) {
    console.error("Error fetching activities: ", error);
    throw new Error(`Failed to fetch activities for user ${userId}`);
  }
};

// Fetch all users
export const fetchAllUsers = async () => {
  try {
    const usersRef = collection(db, "Users"); // Assuming Users collection
    const querySnapshot = await getDocs(usersRef);

    if (querySnapshot.empty) {
      console.log("No users found.");
      return [];
    }

    const users = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));

    console.log("Fetched users: ", users);
    return users;
  } catch (error) {
    console.error("Error fetching users: ", error);
    throw new Error("Failed to fetch users");
  }
};
