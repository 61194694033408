import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase"; // Ensure correct path
import { toast } from "react-toastify";

const ViewSingleBank = () => {
  const { id } = useParams();
  const [bank, setBank] = useState(null);
  const [branches, setBranches] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBankAndBranches = async () => {
      try {
        const bankDoc = await getDoc(doc(db, "Banks", id));
        if (bankDoc.exists()) {
          setBank({ id: bankDoc.id, ...bankDoc.data() });

          const branchesCollection = collection(db, "Banks", id, "Branches");
          const branchesSnapshot = await getDocs(branchesCollection);
          const branchData = branchesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setBranches(branchData);

          const allDepartments = [];
          for (const branch of branchData) {
            const departmentsCollection = collection(
              db,
              "Banks",
              id,
              "Branches",
              branch.id,
              "Departments"
            );
            const departmentsSnapshot = await getDocs(departmentsCollection);
            const branchDepartments = departmentsSnapshot.docs.map((doc) => ({
              id: doc.id,
              branchName: branch.branchname,
              branchLocation: branch.location,
              ...doc.data(),
            }));
            allDepartments.push(...branchDepartments);
          }
          setDepartments(allDepartments);
        } else {
          setError("Bank not found.");
          toast.error("Bank not found.");
        }
      } catch (error) {
        console.error("Error fetching bank data:", error);
        setError("Failed to load bank details.");
        toast.error("Failed to load bank details.");
      } finally {
        setLoading(false);
      }
    };

    fetchBankAndBranches();
  }, [id]);

  if (loading) {
    return <div className="loader-container">Loading...</div>;
  }

  if (error) {
    return <p className="text-red-500">{error}</p>;
  }

  return (
    <div className="p-6 bg-white rounded shadow-md">
      <h1 className="text-2xl font-bold mb-4">Bank Details</h1>
      {bank && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
          <div>
            <p className="text-gray-600 font-semibold">Bank Name:</p>
            <p>{bank.name}</p>
          </div>
          <div>
            <p className="text-gray-600 font-semibold">Headquarters:</p>
            <p>{bank.headquarters}</p>
          </div>
          <div>
            <p className="text-gray-600 font-semibold">Contact Number:</p>
            <p>{bank.contactNumber}</p>
          </div>

        </div>
      )}

      <h2 className="text-xl font-semibold mb-4">Branches</h2>
      {branches.length > 0 ? (
        <div className="overflow-x-auto mb-6">
          <table className="min-w-full bg-gray-50 border border-gray-200">
            <thead>
              <tr className="bg-secondaryColor text-white">
                <th className="px-4 py-2">Branch Name</th>
                <th className="px-4 py-2">Location</th>
                <th className="px-4 py-2">Contact</th>
              </tr>
            </thead>
            <tbody>
              {branches.map((branch) => (
                <tr key={branch.id}>
                  <td className="border px-4 py-2">{branch.branchname}</td>
                  <td className="border px-4 py-2">{branch.location}</td>
                  <td className="border px-4 py-2">{branch.contactNumber}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No branches found for this bank.</p>
      )}

      <h2 className="text-xl font-semibold mb-4">Departments</h2>
      {departments.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-gray-50 border border-gray-200">
            <thead>
              <tr className="bg-secondaryColor text-white">
                <th className="px-4 py-2">Branch Name</th>
              
                <th className="px-4 py-2">Department Name</th>
                <th className="px-4 py-2">Head</th>
                <th className="px-4 py-2">Dept Email</th>
                <th className="px-4 py-2">Contact</th>
              </tr>
            </thead>
            <tbody>
              {departments.map((dept) => (
                <tr key={dept.id}>
                  <td className="border px-4 py-2">{dept.branchName}</td>
                
                  <td className="border px-4 py-2">{dept.departmentName}</td>
                  <td className="border px-4 py-2">{dept.departmentHead}</td>
                  <td className="border px-4 py-2">{dept.departmentEmail}</td>
                  <td className="border px-4 py-2">{dept.contactNumber}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No departments found.</p>
      )}
    </div>
  );
};

export default ViewSingleBank;
