import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { db } from "../../firebase";
import { addDoc, collection } from "firebase/firestore";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import InputField from "../../components/fields/TextInput";
import { logUserActivity } from "../../services/logUserActivity";

const bankSchema = z.object({
  name: z.string().min(1, { message: "Bank Name is required" }).max(30, { message: "Max 30 characters" }),
  headquarters: z.string().min(1, { message: "Location is required" }).max(300, { message: "Max 300 characters" }),
  contactNumber: z.string().min(1, { message: "Contact Number is required" }).max(15, { message: "Max 15 characters" }),
});

const AddBank = () => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: zodResolver(bankSchema) });
  const navigate = useNavigate();
  const [branches, setBranches] = useState([]);
  const [branchInput, setBranchInput] = useState({ branchname: "", location: "", contactNumber: "" });

  const handleAddBranch = () => {
    const { branchname, location, contactNumber } = branchInput;
    if (branchname.trim() && location.trim() && contactNumber.trim()) {
      setBranches([...branches, branchInput]);
      setBranchInput({ branchname: "", location: "", contactNumber: "" });
    } else {
      toast.warn("Please complete all branch fields before adding.");
    }
  };

  const onSubmit = async (data) => {
    try {
      // Add bank document to Banks collection
      const bankRef = await addDoc(collection(db, "Banks"), {
        name: data.name,
        headquarters: data.headquarters,
        contactNumber: data.contactNumber
      });

      // Retrieve bankId from newly added bank
      const bankId = bankRef.id;

      // Add each branch as a document in the Branches subcollection under the bank
      for (const branch of branches) {
        await addDoc(collection(db, "Banks", bankId, "Branches"), branch);
      }

      toast.success("Bank and branches added successfully!");
      logUserActivity("User Added Bank and Branches");
      reset();
      setBranches([]);
      navigate("/banks");
    } catch (error) {
      console.error("Error adding document:", error);
      toast.error("Failed to add bank.");
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg mx-auto">
      <h1 className="text-2xl font-semibold mb-4">Create Bank</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <InputField label="Bank Name" error={errors.name?.message} register={register} name="name" />
        <InputField label="Headquarters Location" error={errors.headquarters?.message} register={register} name="headquarters" />
        <InputField label="Contact Number" error={errors.contactNumber?.message} register={register} name="contactNumber" />
        
        <h2 className="text-lg font-medium mt-6">Add Branches</h2>
        <div className="grid grid-cols-3 gap-2">
          <InputField label="Branch ID" value={branchInput.branchname} onChange={(e) => setBranchInput(prev => ({ ...prev, branchname: e.target.value }))} />
          <InputField label="Location" value={branchInput.location} onChange={(e) => setBranchInput(prev => ({ ...prev, location: e.target.value }))} />
          <InputField label="Contact" value={branchInput.contactNumber} onChange={(e) => setBranchInput(prev => ({ ...prev, contactNumber: e.target.value }))} />
        </div>
        
        <button type="button" onClick={handleAddBranch} className="text-blue-500 mt-4">Add Branch</button>
        <div className="overflow-x-auto mt-4">
          <table className="min-w-full bg-gray-50 border border-gray-200">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-4 py-2">Branch Name</th>
                <th className="px-4 py-2">Location</th>
                <th className="px-4 py-2">Contact</th>
              </tr>
            </thead>
            <tbody>
              {branches.map((branch, index) => (
                <tr key={index}>
                  <td className="border px-4 py-2">{branch.branchname}</td>
                  <td className="border px-4 py-2">{branch.location}</td>
                  <td className="border px-4 py-2">{branch.contactNumber}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <button type="submit" className="w-full text-white bg-blue-600 rounded-lg py-2 mt-4">Add Bank</button>
      </form>
    </div>
  );
};

export default AddBank;
