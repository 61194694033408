import React, { createContext, useContext, useState, useEffect } from 'react';

// Create the context with the default value being undefined
const LayoutDirectionContext = createContext(undefined);

// Custom hook to use the LayoutDirection context
export const useLayoutDirection = () => {
  const context = useContext(LayoutDirectionContext);
  if (!context) {
    throw new Error('useLayoutDirection must be used within a LayoutDirectionProvider');
  }
  return context;
};

// LayoutDirectionProvider component
export const LayoutDirectionProvider = ({ children }) => {
  const [isRTL, setIsRTL] = useState(() => {
    const storedIsRTL = localStorage.getItem('isRTL');
    return storedIsRTL === 'true';
  });

  const toggleLayoutDirection = (boolean) => {
    setIsRTL(boolean);
  };

  useEffect(() => {
    localStorage.setItem('isRTL', isRTL.toString());
  }, [isRTL]);

  useEffect(() => {
    // After the language direction changes, update the root HTML element
    const rootHtml = document.getElementById('root');
    if (rootHtml) {
      rootHtml.setAttribute('dir', isRTL ? 'rtl' : 'ltr');
    }
  }, [isRTL]);

  return (
    <LayoutDirectionContext.Provider value={{ isRTL, toggleLayoutDirection }}>
      {children}
    </LayoutDirectionContext.Provider>
  );
};
