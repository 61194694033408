import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useUserAuth } from "../../context/UserAuthContext";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { fetchPermissions } from "../../redux/permissionsSlice";

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { logIn } = useUserAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
  
    try {
      const userCredential = await logIn(email, password);
      const user = userCredential.user;
  
      // Fetch the user's roleId from the Users collection
      const userDocRef = doc(db, "Users", user.uid);
      const userDoc = await getDoc(userDocRef);
  
      if (userDoc.exists()) {
        const { roleId } = userDoc.data();
        const username = user.displayName || "User"; // Use displayName or fallback to 'User'
  
        // Store user data locally
        localStorage.setItem(
          "user",
          JSON.stringify({ 
            email: user.email, 
            userId: user.uid, 
            roleId, 
            username 
          })
        );
  
        // Fetch permissions for the user's role and store in Redux
        await dispatch(fetchPermissions(roleId)).unwrap();
  
        toast.success("User Logged In Successfully!", {
          position: "top-center",
        });
        navigate("/"); // Redirect to home
      } else {
        throw new Error("User data not found.");
      }
    } catch (err) {
      setError(err.message);
      toast.error(err.message, { position: "top-center" });
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-secondaryColor to-primaryColor">
      <div className="bg-white shadow-lg rounded-lg p-8 w-96">
        <h2 className="text-center text-2xl font-bold text-gray-800 mb-4">
          Login
        </h2>
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="email"
            placeholder="Email or phone number"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-4 py-2 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-400"
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full px-4 py-2 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-400"
            required
          />
          <button
            type="submit"
            disabled={loading}
            className="w-full py-2 mt-6 text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg"
          >
            {loading ? "Signing In..." : "Sign In"}
          </button>
        </form>
        <p className="text-center my-4">
          Don't have an account?{" "}
          <Link to="/signup" className="text-blue-800">
            Create an account
          </Link>
        </p>
      </div>
    </div>
  );
}

export default SignIn;
