import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { FaTrash, FaPencilAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { db } from "../../firebase";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { toast } from "react-toastify";
import { logUserActivity } from "../../services/logUserActivity";
import { fetchPermissions } from "../../redux/permissionsSlice";
import { useDispatch, useSelector } from "react-redux";

const Departments = () => {
  const dispatch = useDispatch();
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { permissions } = useSelector((state) => state.permissions);

  console.log("permissions", permissions);


  const permissionObject = Array.isArray(permissions)
  ? permissions.find((perm) => typeof perm === "object") || {}
  : permissions; // Assuming permissions might already be an object

const canAdd = permissionObject.addDepartment || false;
const canEdit = permissionObject.updateDepartment || false;
const canDelete = permissionObject.deleteDepartment || false;

  useEffect(() => {
    dispatch(fetchPermissions());
  }, [dispatch]);

  const columns = [
    {
      field: "serialNo",
      headerName: "Serial No",
      flex: 0.5,
      headerClassName: "header",
    },
    {
      field: "departmentName",
      headerName: "Department Name",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "departmentHead",
      headerName: "Department Head",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "contactNumber",
      headerName: "Contact",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "departmentEmail",
      headerName: "Department Email",
      flex: 1,
      headerClassName: "header",
    },
    
    
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      headerClassName: "header",
      renderCell: (params) => (
        <div className="flex items-center gap-2 h-full"> {/* Ensure the parent has full height */}
          {/* Edit Icon */}
          {canEdit && (
            <Link to={`/update-services/${params.row.id}`} className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8">
              <FaPencilAlt className="text-white cursor-pointer h-4 w-4" />
            </Link>
          )}
          
          {/* Delete Icon */}
          {canDelete && (
            <div 
              onClick={() => handleDelete(params.row.id)} 
              className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8 cursor-pointer"
            >
              <FaTrash className="text-white h-4 w-4" />
            </div>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const banksCollectionRef = collection(db, "Banks");
        const bankDocs = await getDocs(banksCollectionRef);
  
        const departmentsData = [];
        let serialCounter = 1; // Initialize serial counter
  
        for (const bankDoc of bankDocs.docs) {
          const branchesCollectionRef = collection(bankDoc.ref, "Branches");
          const branchDocs = await getDocs(branchesCollectionRef);
  
          for (const branchDoc of branchDocs.docs) {
            const departmentsCollectionRef = collection(branchDoc.ref, "Departments");
            const departmentDocs = await getDocs(departmentsCollectionRef);
  
            departmentDocs.forEach((doc) => {
              // Capture current value of serialCounter in a local variable
              const currentSerial = serialCounter;
  
              departmentsData.push({
                id: doc.id,
                serialNo: currentSerial, // Use local variable to avoid capturing outer scope
                bankId: bankDoc.id,
                branchId: branchDoc.id,
                ...doc.data(),
              });
  
              serialCounter++; // Increment after adding to the array
            });
          }
        }
  
        setDepartments(departmentsData);
        logUserActivity("User Viewed Departments");
      } catch (error) {
        console.error("Error fetching Departments: ", error);
        setError("Failed to load Departments.");
      } finally {
        setLoading(false);
      }
    };
  
    fetchDepartments();
  }, []);
  
  

  const handleDelete = async (rowData) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this department?");
    if (confirmDelete) {
      try {
        const { bankId, branchId, id } = rowData;
        await deleteDoc(doc(db, "Banks", bankId, "Branches", branchId, "Departments", id));
        setDepartments(departments.filter((item) => item.id !== id));
        toast.success("Department deleted successfully!");
        logUserActivity("User Deleted Department");
      } catch (error) {
        console.error("Error deleting department data: ", error);
        setError("Failed to delete department data.");
        toast.error("Failed to delete department data.");
      }
    }
  };

  return (
    <div style={{ height: 400, width: "100%" }}>
      <div className="flex justify-between items-center mb-4">
        <div>
          <h1 className="font-bold text-xl">Departments</h1>
        </div>
        <div>
          {canAdd && (
            <Link
              to="/add-department"
              className="bg-secondaryColor text-white hover:bg-primaryColor border rounded-xl px-4 flex py-2 gap-2 items-center border-themeColor text-sm font-semibold cursor-pointer"
            >
              Add Department
            </Link>
         )} 
        </div>
      </div>

      {loading ? (
        <div className="loader-container">Loading...</div>
      ) : error ? (
        <p>{error}</p>
      ) : departments.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-[80vh]">
          <p className="text-gray-500">No departments found</p>
        </div>
      ) : (
        <DataGrid
          rows={departments}
          columns={columns}
          getRowId={(row) => row.id}
          sx={{
            "& .header": {
              backgroundColor: "#214783",
              color: "#FFFFFF",
              fontWeight: "bold",
              fontSize: "16px",
            },
            "& .MuiDataGrid-cell": {
              backgroundColor: "#f4f4f4",
              color: "#000",
            },
          }}
        />
      )}
    </div>
  );
};

export default Departments;
