import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { fetchActivitiesForUser, fetchAllUsers } from "../../services/loggingService";
import { toast } from "react-toastify";

const ViewActivities = () => {
  const [allActivities, setAllActivities] = useState([]);
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchDate, setSearchDate] = useState("");

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const fetchedUsers = await fetchAllUsers();
        const allActivitiesList = [];

        for (const user of fetchedUsers) {
          const activities = await fetchActivitiesForUser(user.id);
          const userActivities = activities.map((activity) => ({
            ...activity,
            userId: user.id,
            id: `${user.id}-${activity.timestamp}`, // Unique ID for DataGrid
          }));
          allActivitiesList.push(...userActivities);
        }

        setAllActivities(allActivitiesList);
        setFilteredActivities(allActivitiesList); // Initialize filtered data
      } catch (error) {
        console.error("Error fetching users or activities: ", error);
        setError("Failed to load activities.");
        toast.error("Failed to load activities.");
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, []);

  const handleSearch = (event) => {
    const inputDate = event.target.value;
    setSearchDate(inputDate);

    if (inputDate) {
      const filtered = allActivities.filter((activity) =>
        new Date(activity.timestamp).toLocaleDateString() === new Date(inputDate).toLocaleDateString()
      );
      setFilteredActivities(filtered);
    } else {
      setFilteredActivities(allActivities);
    }
  };

  const columns = [
    {
      field: "timestamp",
      headerName: "Timestamp",
      flex: 1,
      headerClassName: "header",
      renderCell: (params) => {
        const date = new Date(params.value);
        return date.toLocaleString("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });
      },
    },
    {
      field: "userId",
      headerName: "User ID",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "metadata",
      headerName: "Metadata",
      flex: 2,
      headerClassName: "header",
      renderCell: (params) => JSON.stringify(params?.row?.metadata),
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <div className="flex justify-between">
        <div>
          <h1 className="font-bold text-xl mb-4">User Activities</h1>
        </div>
        <div className="mb-4">
          <label htmlFor="search-date" className="mr-2">Search by Date:</label>
          <input
            type="date"
            id="search-date"
            value={searchDate}
            onChange={handleSearch}
            className="p-2 border rounded"
          />
        </div>
      </div>
      {loading ? (
        <div className="loader-container">Loading...</div>
      ) : error ? (
        <p>{error}</p>
      ) : filteredActivities.length === 0 ? (
        <div className="flex justify-center items-center h-[80vh]">
          <p className="text-gray-500">No activities found.</p>
        </div>
      ) : (
        <DataGrid
          rows={filteredActivities}
          columns={columns}
          getRowId={(row) => row.id}
          sx={{
            "& .header": {
              backgroundColor: "#214783",
              color: "#FFFFFF",
              fontWeight: "bold",
              fontSize: "16px",
            },
            "& .MuiDataGrid-cell": {
              backgroundColor: "#f4f4f4",
              color: "#000",
            },
          }}
        />
      )}
    </div>
  );
};

export default ViewActivities;
