import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase"; // Ensure Firebase is configured correctly

export const monitorLogs = async () => {
  const activityRef = collection(db, "GlobalActivityLogs");
  const querySnapshot = await getDocs(activityRef);

  if (querySnapshot.empty) {
    console.warn("No activity logs found.");
  } else {
    console.log(`Found ${querySnapshot.size} logs.`);
    // Add more detailed monitoring logic here, e.g., checking for error logs
  }
};

export const startMonitoring = () => {
  setInterval(() => {
    monitorLogs();
  }, 60000); // Check every minute
};
