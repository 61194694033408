import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { loanSchema } from "../../models/loanProgramSchema";
import { toast } from "react-toastify";
import { db } from "../../firebase";
import { addDoc, collection, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import InputField from "../../components/fields/TextInput";
import Checkbox from "../../components/CheckBox";
import SelectField from "../../components/fields/SelectField";
import MultiSelect from "../../components/MultiSelect";

const AddLoanSchemeForm = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    resolver: zodResolver(loanSchema),
    defaultValues: {
      baseFields: {
        programName: "",
        selectedBank: "",
        eligibilityCriteria: {
          minSalary: "",
          maxDBR: "",
          creditScore: "",
          ageLimit: "",
        },
        loanDetails: {
          minLoanAmount: "",
          maxLoanAmount: "",
          interestRate: "",
          tenure: [],
        },
        requiredDocuments: [],
      },
      programSpecificFields: {
        prePaymentPenalty: false,
        insuranceRequired: false,
      },
    },
  });

  const navigate = useNavigate();
  const [tenureOptions] = useState([12, 24, 36, 48, 60]);
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState("");
  const [requiredDocs] = useState([
    "Bank Statement",
    "ID Proof",
    "Salary Certificate",
    "Car Purchase Agreement",
  ]);

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const banksCollectionRef = collection(db, "Banks");
        const bankSnapshot = await getDocs(banksCollectionRef);
        const banksData = bankSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBanks(banksData);
      } catch (error) {
        console.error("Error fetching banks:", error);
      }
    };

    fetchBanks();
  }, []);

  useEffect(() => {
    setValue("baseFields.selectedBank", selectedBank);
  }, [selectedBank, setValue]);

  const onSubmit = async (data) => {
    try {
      await addDoc(collection(db, "LoanProgram"), data);
      toast.success("Successfully added loan program!");
      navigate("/loanprogram");
    } catch (error) {
      console.error("Error adding document: ", error);
      toast.error("Failed to submit loan program.");
    }
  };

  return (
    <div>
      <h1 className="font-bold text-xl">Create Loan Program</h1>
      <div className="rounded-lg shadow-lg">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-[90%] mx-auto flex flex-col gap-4 p-10"
        >
          <SelectField
            label="Program Type"
            options={[
              { id: "personal_loan", name: "Personal Loan" },
              { id: "car_loan", name: "Car Loan" },
              { id: "commercial_pos", name: "Commercial POS" },
            ]}
            name="programType"
            register={register}
            error={errors.programType?.message}
          />

          <InputField
            label="Program Name"
            name="baseFields.programName"
            register={register}
            error={errors.baseFields?.programName?.message}
          />

          <label className="font-medium">Select Bank</label>
          <select
            value={selectedBank}
            onChange={(e) => {
              setSelectedBank(e.target.value);
              setValue("baseFields.selectedBank", e.target.value);
            }}
            className="border border-gray-300 rounded-lg p-2"
          >
            <option value="">Select a Bank</option>
            {banks.map((bank) => (
              <option key={bank.id} value={bank.name}>
                {bank.name}
              </option>
            ))}
          </select>

          <InputField
            label="Minimum Salary"
            name="baseFields.eligibilityCriteria.minSalary"
            type="number"
            register={register}
            error={errors.baseFields?.eligibilityCriteria?.minSalary?.message}
          />

          <InputField
            label="Maximum DBR (%)"
            name="baseFields.eligibilityCriteria.maxDBR"
            type="number"
            register={register}
            error={errors.baseFields?.eligibilityCriteria?.maxDBR?.message}
          />

          <InputField
            label="Credit Score"
            name="baseFields.eligibilityCriteria.creditScore"
            type="number"
            register={register}
            error={errors.baseFields?.eligibilityCriteria?.creditScore?.message}
          />

          <InputField
            label="Age Limit"
            name="baseFields.eligibilityCriteria.ageLimit"
            type="number"
            register={register}
            error={errors.baseFields?.eligibilityCriteria?.ageLimit?.message}
          />

          <InputField
            label="Minimum Loan Amount"
            name="baseFields.loanDetails.minLoanAmount"
            type="number"
            register={register}
            error={errors.baseFields?.loanDetails?.minLoanAmount?.message}
          />

          <InputField
            label="Maximum Loan Amount"
            name="baseFields.loanDetails.maxLoanAmount"
            type="number"
            register={register}
            error={errors.baseFields?.loanDetails?.maxLoanAmount?.message}
          />

          <InputField
            label="Interest Rate (%)"
            name="baseFields.loanDetails.interestRate"
            type="number"
            register={register}
            error={errors.baseFields?.loanDetails?.interestRate?.message}
          />

          <MultiSelect
            label="Tenure (Months)"
            options={tenureOptions}
            name="baseFields.loanDetails.tenure"
            setValue={setValue}
            watch={watch}
            register={register}
            error={errors.baseFields?.loanDetails?.tenure?.message}
          />

          <MultiSelect
            label="Required Documents"
            options={requiredDocs}
            name="baseFields.requiredDocuments"
            setValue={setValue}
            watch={watch}
            register={register}
            error={errors.baseFields?.requiredDocuments?.message}
          />

          <Checkbox
            label="Pre-Payment Penalty"
            name="programSpecificFields.prePaymentPenalty"
            register={register}
          />

          <Checkbox
            label="Insurance Required"
            name="programSpecificFields.insuranceRequired"
            register={register}
          />

          <button
            type="submit"
            className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6 my-6"
          >
            Submit Loan Scheme
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddLoanSchemeForm;
