import { getAuth } from "firebase/auth";
import { autoLogUserActivity } from "./loggingService";

export const logUserActivity = async (action) => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (user) {
    const userId = user.uid;

    // Retrieve the stored user data from localStorage
    const storedUser = localStorage.getItem("user");
    const { username } = storedUser ? JSON.parse(storedUser) : { username: "Unknown User" };

    try {
      const details = {}; // Initialize the details object to hold extra information
      await autoLogUserActivity(userId, username, action, details);
    } catch (error) {
      console.error("Failed to log user activity: ", error);
    }
  } else {
    console.error("No user is logged in.");
  }
};
