import { Navigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext"; // Import the correct function

function ProtectedRoute({ children }) {
  const { user } = useUserAuth(); // Use useUserAuth instead of useAuth

  return user ? children : <Navigate to="/signin" replace />;
}

export default ProtectedRoute;
