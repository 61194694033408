import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { FaTrash, FaPencilAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { db } from "../../firebase";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { logUserActivity } from "../../services/logUserActivity";
import { fetchPermissions } from "../../redux/permissionsSlice";
import { useDispatch, useSelector } from "react-redux";

const LoanSchemes = () => {
  const [loanSchemes, setLoanSchemes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const { permissions } = useSelector((state) => state.permissions);

  console.log("permissions", permissions);

  // Extract permissions object from the first item
  const permissionObject = permissions.find((perm) => typeof perm === "object") || {};

  // Check specific permissions
  const canAdd = permissionObject.addBank;
  const canEdit = permissionObject.updateBank;
  const canDelete = permissionObject.deleteBank;

  useEffect(() => {
    dispatch(fetchPermissions());
  }, [dispatch]);

  // Define columns with separate headers for criteria details
  const columns = [
    {
      field: "serialNo",
      headerName: "Serial No",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "loanType",
      headerName: "Loan Type",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "schemeName",
      headerName: "Scheme Name",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "minAmount",
      headerName: "Min Amount",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "maxAmount",
      headerName: "Max Amount",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "eligibilityAge",
      headerName: "Eligibility Age",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "employmentStatus",
      headerName: "Employment Status",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      headerClassName: "header",
      renderCell: (params) => (
        <div className="flex items-center gap-2 h-full">
          {/* Edit Icon - Render based on permission */}
          {canEdit && (
            <Link to={`/update-services/${params.row.id}`} className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8">
              <FaPencilAlt className="text-white cursor-pointer h-4 w-4" />
            </Link>
          )}

          {/* Delete Icon - Render based on permission */}
          {canDelete && (
            <div onClick={() => handleDelete(params.row.id)} className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8 cursor-pointer">
              <FaTrash className="text-white h-4 w-4" />
            </div>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    const fetchLoanSchemes = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "LoanSchemes"));
        const loanSchemesData = querySnapshot.docs.map((doc, index) => {
          const data = doc.data();
          return {
            id: doc.id,
            serialNo: index + 1,
            loanType: data.loanType,
            schemeName: data.schemeName,
            minAmount: data.criteria[0]?.minAmount || "",
            maxAmount: data.criteria[0]?.maxAmount || "",
            eligibilityAge: data.criteria[0]?.eligibilityAge || "",
            employmentStatus: data.criteria[0]?.employmentStatus || "",
          };
        });
        setLoanSchemes(loanSchemesData);
        logUserActivity("User Viewed Loan Scheme");
      } catch (error) {
        console.error("Error fetching loan schemes: ", error);
        setError("Failed to load loan schemes.");
      } finally {
        setLoading(false);
      }
    };

    fetchLoanSchemes();
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this loan scheme?");
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "LoanSchemes", id));
        setLoanSchemes(loanSchemes.filter((scheme) => scheme.id !== id));
        logUserActivity("User Deleted Loan Scheme");
      } catch (error) {
        console.error("Error deleting loan scheme: ", error);
        setError("Failed to delete loan scheme.");
      }
    }
  };

  return (
    <div style={{ height: 400, width: "100%" }}>
      <div className="flex justify-between items-center mb-4">
        <div>
          <h1 className="font-bold text-xl">Loan Schemes</h1>
        </div>

        {/* Render Add Button based on permission */}
        {canAdd && (
          <div>
            <button className="bg-secondaryColor text-white hover:bg-primaryColor border rounded-xl px-4 flex py-2 gap-2 items-center border-themeColor text-sm font-semibold cursor-pointer">
              <Link to="/add-loanscheme">Add Loan Scheme</Link>
            </button>
          </div>
        )}
      </div>

      {loading ? (
        <div className="loader-container">Loading...</div>
      ) : error ? (
        <p>{error}</p>
      ) : loanSchemes.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-[80vh]">
          <p className="text-gray-500">No loan schemes found</p>
        </div>
      ) : (
        <DataGrid
          rows={loanSchemes}
          columns={columns}
          getRowId={(row) => row.id}
          sx={{
            "& .header": {
              backgroundColor: "#214783",
              color: "#FFFFFF",
              fontWeight: "bold",
              fontSize: "16px",
            },
            "& .MuiDataGrid-cell": {
              backgroundColor: "#f4f4f4",
              color: "#000",
            },
          }}
        />
      )}
    </div>
  );
};

export default LoanSchemes;
