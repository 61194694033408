export class ActivityLog {
  constructor(userId, username, action, metadata, message) {
    this.userId = userId;
    this.username = username;
    this.action = {
      type: action,
      message: message || "", // Store the generated message
    };
    this.deviceType = metadata.deviceType;
    this.os = metadata.os;
    this.timestamp = new Date().toISOString();
  }

  validate() {
    if (!this.userId || !this.username || !this.action.type) {
      throw new Error("UserId, username, and action type are required fields.");
    }
    if (!this.deviceType || !this.os) {
      throw new Error("DeviceType and OS are required in metadata.");
    }
  }

  toPlainObject() {
    return {
      userId: this.userId,
      username: this.username,
      action: this.action,
      deviceType: this.deviceType,
      os: this.os,
      timestamp: this.timestamp,
    };
  }
}
