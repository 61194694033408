import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import InputField from "../../components/fields/TextInput";
import { z } from "zod";
import { db } from "../../firebase";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { toast } from "react-toastify";
import { logUserActivity } from "../../services/logUserActivity";
import { useNavigate } from "react-router-dom";

// Define schema
const dataSchema = z.object({
  featureName: z
    .string()
    .min(1, { message: "Feature name is required" })
    .max(30, { message: "Feature name cannot exceed 30 characters" }),

  restricted: z.boolean().optional(),
});

const AddPermission = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(dataSchema),
  });

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      // Add timestamps to the data
      const permissionData = {
        ...data,
        restricted: data.restricted || false, // Default to false if not specified
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
      };

      const docRef = await addDoc(
        collection(db, "permissions"),
        permissionData
      );
      console.log("Document written with ID: ", docRef.id);
      toast.success("Permission successfully added!");

      logUserActivity("User added a new permission");
      navigate("/permission");
    } catch (error) {
      console.error("Error adding document: ", error);
      toast.error("Failed to add permission.");
    }
  };

  return (
    <>
      <div>
        <h1 className="font-bold text-xl">Create Permission</h1>
      </div>

      <div className="rounded-lg shadow">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-[90%] mx-auto flex flex-col gap-4 p-10">
            <InputField
              label="Feature Name"
              error={errors.featureName?.message}
              register={register}
              name="featureName"
            />

            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                {...register("restricted")}
                className="form-checkbox"
              />
              <span>Restricted</span>
            </label>

            <button
              type="submit"
              className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6 my-6"
            >
              Add Permission
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddPermission;
