import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { DataGrid } from "@mui/x-data-grid";
import { db } from "../../firebase";
import { Link } from "react-router-dom";
import { FaPencilAlt, FaTrash, FaEye } from "react-icons/fa";

const LoanApplicationTable = () => {
  const [loanApplications, setLoanApplications] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLoanApplications = async () => {
      try {
        const appliedLoanRef = collection(db, "AppliedLoanApplication");
        const querySnapshot = await getDocs(appliedLoanRef);
        const loanData = querySnapshot.docs.map((doc, index) => ({
          serialNo: index + 1, // Dynamic Serial No
          id: doc.id, // Document ID
          ...doc.data(), // Document data
        }));
        setLoanApplications(loanData);
      } catch (error) {
        setError("Error fetching loan applications. Please try again later.");
        console.error("Error fetching loan applications:", error);
      } finally {
        setLoading(false); // Ensure loading is stopped
      }
    };

    fetchLoanApplications();
  }, []);

  const columns = [
    {
      field: "serialNo",
      headerName: "Serial No",
      flex: 0.5,
      headerClassName: "header",
    },
    {
      field: "loanProgram",
      headerName: "Loan Program",
      flex: 1,
      headerClassName: "header",
    },
    // {
    //   field: "email",
    //   headerName: "Email",
    //   flex: 1,
    //   headerClassName: "header",
    // },
    // {
    //   field: "contact",
    //   headerName: "Contact",
    //   flex: 1,
    //   headerClassName: "header",
    // },
    {
        field: "bank",
        headerName: "Bank",
        flex: 1,
        headerClassName: "header",
      },
    {
      field: "loanAmount",
      headerName: "Loan Amount",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "approvalStatus",
      headerName: "Status",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      headerClassName: "header",
      renderCell: (params) => (
        <div className="flex items-center gap-2 h-full">
          {/* View Icon */}
          <Link
            to={`/view-loan/${params.row.id}`}
            className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8"
          >
            <FaEye className="text-white cursor-pointer h-4 w-4" />
          </Link>

          {/* Edit Icon */}
          <Link
            to={`/update-services/${params.row.id}`}
            className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8"
          >
            <FaPencilAlt className="text-white cursor-pointer h-4 w-4" />
          </Link>

          {/* Delete Icon */}
          <div
            className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8 cursor-pointer"
          >
            <FaTrash className="text-white h-4 w-4" />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <div className="flex justify-between items-center mb-4">
        <h1 className="font-bold text-xl">Loan Applications</h1>
      </div>

      {loading ? (
        <div className="loader-container">Loading...</div>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : loanApplications.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-[80vh]">
          <p className="text-gray-500">No data found</p>
        </div>
      ) : (
        <DataGrid
          rows={loanApplications}
          columns={columns}
          getRowId={(row) => row.id}
          sx={{
            "& .header": {
              backgroundColor: "#214783",
              color: "#FFFFFF",
              fontWeight: "bold",
              fontSize: "16px",
            },
            "& .MuiDataGrid-cell": {
              backgroundColor: "#f4f4f4",
              color: "#000",
            },
          }}
        />
      )}
    </div>
  );
};

export default LoanApplicationTable;
