import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { FaTrash, FaPencilAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { db } from "../../firebase"; // Adjust the import based on your project structure
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { logUserActivity } from "../../services/logUserActivity";

const ViewPermission = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);



  const columns = [
    {
      field: "serialNo",
      headerName: "Serial No",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "featureName",
      headerName: "Feature Name",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      headerClassName: "header",
      renderCell: (params) => (
        <div className="flex items-center gap-2 h-full">
          {" "}
          {/* Ensure the parent has full height */}
          {/* Edit Icon */}
          <Link
            to={`/update-services/${params.row.id}`}
            className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8"
          >
            <FaPencilAlt className="text-white cursor-pointer h-4 w-4" />
          </Link>
          {/* Delete Icon */}
          <div
            onClick={() => handleDelete(params.row.id)}
            className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8 cursor-pointer"
          >
            <FaTrash className="text-white h-4 w-4" />
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "permissions"));
        const products = querySnapshot.docs.map((doc, index) => ({
          id: doc.id,
          serialNo: index + 1, // Add serial number based on the index
          ...doc.data(),
        }));
        setData(products);
        logUserActivity("User Viewed permissions");
      } catch (error) {
        console.error("Error fetching permissions: ", error);
        setError("Failed to load permissions.");
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this permissions?"
    );
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "permissions", id)); // Delete product from Firestore
        setData(data.filter((item) => item.id !== id)); // Update state to remove the deleted item
        logUserActivity("User Deleted permissions");
      } catch (error) {
        console.error("Error deleting permissions: ", error);
        setError("Failed to delete permissions."); // Handle error
      }
    }
  };

  return (
    <div style={{ height: 400, width: "100%" }}>
      <div className="flex justify-between items-center mb-4">
        <div>
          <h1 className="font-bold text-xl">Permissions</h1>
        </div>
        <div>
          <button className="bg-secondaryColor text-white hover:bg-primaryColor border rounded-xl px-4 flex py-2 gap-2 items-center border-themeColor text-sm font-semibold cursor-pointer">
            <Link to="/add-permission">Add Permission</Link>
          </button>
        </div>
      </div>

      {loading ? (
        <div className="loader-container">Loading...</div>
      ) : error ? (
        <p>{error}</p>
      ) : data.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-[80vh]">
          <p className="text-gray-500">No products found</p>
        </div>
      ) : (
        <DataGrid
          rows={data}
          columns={columns}
          getRowId={(row) => row.id}
          sx={{
            "& .header": {
              backgroundColor: "#214783",
              color: "#FFFFFF",
              fontWeight: "bold",
              fontSize: "16px",
            },
            "& .MuiDataGrid-cell": {
              backgroundColor: "#f4f4f4",
              color: "#000",
            },
          }}
        />
      )}
    </div>
  );
};

export default ViewPermission;
