import React, { useEffect, useState } from "react";
import { db } from "../../firebase"; // Assuming you're using Firebase
import { collection, getDocs, addDoc , updateDoc, doc, arrayUnion, setDoc } from "firebase/firestore";
import { toast } from "react-toastify";

const FProgram = () => {
  const [searchCriteria, setSearchCriteria] = useState({
    programType: "", // Default to 'Personal Loan'
    basicSalary: 0, // Default to 9000
    financialAmount: 0, // Default to 60000
    duration: 0, // Default to 36 months
  });

  // Modal state
  const [showModal, setShowModal] = useState(false);
  const [loanId, setLoanId] = useState(null);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [documents, setDocuments] = useState({
    bank_statement: null,
    dbr_ratio: null,
    salary_definition: null,
  });

  const [loanPrograms, setLoanPrograms] = useState([]);
  const [error, setError] = useState(null);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const clientsCollectionRef = collection(db, "Clients");
        const clientSnapshot = await getDocs(clientsCollectionRef);
        const clientsData = clientSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setClients(clientsData); // Set fetched clients
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };

    fetchClients();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchCriteria((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDocumentChange = (e) => {
    const { name, files } = e.target;
    setDocuments((prevDocs) => ({
      ...prevDocs,
      [name]: files[0],
    }));
  };

  const handleClientChange = (e) => {
    const selectedClientId = e.target.value;
    const client = clients.find((client) => client.id === selectedClientId);
    setSelectedClient(client);

    // Update basicSalary with the selected client's annualIncome
    setSearchCriteria((prev) => ({
      ...prev,
      basicSalary: client ? client.annualIncome : prev.basicSalary,
    }));
  };

  const findLoanPrograms = async () => {
    try {
      const loanProgramsRef = collection(db, "LoanProgram");
      const querySnapshot = await getDocs(loanProgramsRef);

      if (querySnapshot.empty) {
        setLoanPrograms([]);
        return;
      }

      const programs = querySnapshot.docs.map((doc) => doc.data());

      const filteredPrograms = programs.filter((program) => {
        const programTypeMatches =
          program.baseFields.programName === searchCriteria.programType;
        const salaryMatches =
          parseInt(program.baseFields.eligibilityCriteria.minSalary) <=
          parseInt(searchCriteria.basicSalary);
        const loanAmountMatches =
          parseInt(program.baseFields.loanDetails.maxLoanAmount) >=
          parseInt(searchCriteria.financialAmount);
        const durationMatches = program.baseFields.loanDetails.tenure.includes(
          searchCriteria.duration.toString()
        );

        return (
          programTypeMatches &&
          salaryMatches &&
          loanAmountMatches &&
          durationMatches
        );
      });

      const sortedPrograms = filteredPrograms.sort((a, b) => {
        const salaryDifferenceA = Math.abs(
          parseInt(a.baseFields.eligibilityCriteria.minSalary) -
            parseInt(searchCriteria.basicSalary)
        );
        const salaryDifferenceB = Math.abs(
          parseInt(b.baseFields.eligibilityCriteria.minSalary) -
            parseInt(searchCriteria.basicSalary)
        );

        const loanAmountDifferenceA = Math.abs(
          parseInt(a.baseFields.loanDetails.maxLoanAmount) -
            parseInt(searchCriteria.financialAmount)
        );
        const loanAmountDifferenceB = Math.abs(
          parseInt(b.baseFields.loanDetails.maxLoanAmount) -
            parseInt(searchCriteria.financialAmount)
        );

        return (
          salaryDifferenceA +
          loanAmountDifferenceA -
          (salaryDifferenceB + loanAmountDifferenceB)
        );
      });

      setLoanPrograms(sortedPrograms);
    } catch (error) {
      console.error("Error fetching loan programs:", error);
      setError(
        "There was an error fetching the loan programs. Please try again later."
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);
    console.log("Searching with criteria:", searchCriteria);
    findLoanPrograms();
  };

  const handleApply = async (program) => {
    setShowModal(true);
    if (!selectedClient) {
      alert("Please select a client first.");
      return;
    }
  
    try {
      // Create the loan application data
      const loanApplicationData = {
        userId: selectedClient.id, // Use the selected client's ID
        contact: selectedClient.contact, // Adding client's contact
        email: selectedClient.email,
        loanProgram: program.baseFields.programName,
        bank: program.baseFields.selectedBank || "Unknown Bank", // Use the bank name if available
        eligibilityStatus: "In Progress", // Set as eligible by default, can be dynamic
        approvalStatus: "In Progress", // Set as approved by default, can be dynamic
        approvalDate: new Date().toISOString(), // Current date
        loanAmount: searchCriteria.financialAmount,
        creditLimit: program.baseFields.loanDetails.maxLoanAmount, // Get credit limit from the loan program
        interestRate: program.baseFields.loanDetails.interestRate || 5.5, // Default interest rate
      };
  
      // Add document to AppliedLoanApplication collection (only once)
      const appliedLoanRef = collection(db, "AppliedLoanApplication");
      const docRef = await addDoc(appliedLoanRef, loanApplicationData);
  
      const loanId = docRef.id;
      console.log("Loan application submitted with document ID:", loanId);
  
      setLoanId(loanId);
      toast.success("Your loan application has been submitted successfully.");
    } catch (error) {
      console.error("Error submitting loan application:", error);
      alert("There was an error submitting your application.");
    }
  };
  

  const handleSubmitLoanDocuments = async (e) => {
    e.preventDefault();
  
    if (
      !documents.bank_statement ||
      !documents.dbr_ratio ||
      !documents.salary_definition
    ) {
      alert("Please upload all the required documents.");
      return;
    }
  
    if (!loanId) {
      alert("Loan ID is missing. Please apply for a loan first.");
      return;
    }
  
    try {
      // Step 1: Submit Loan Documents (unchanged)
      const loanDocumentData = {
        loanId,
        documents: {
          bank_statement: {
            status: "submitted",
            url: URL.createObjectURL(documents.bank_statement),
          },
          dbr_ratio: {
            status: "approved",
            url: URL.createObjectURL(documents.dbr_ratio),
          },
          salary_definition: {
            status: "submitted",
            url: URL.createObjectURL(documents.salary_definition),
          },
        },
      };
  
      const loanDocumentsRef = collection(db, "LoanDocuments");
      await addDoc(loanDocumentsRef, loanDocumentData);
  
      // Step 2: Create or Update LoanHistories
      const loanHistoryRef = doc(db, "LoanHistories", loanId);
      const historyEntry = {
        timestamp: new Date().toISOString(),
        document_action: "Document Submitted",
        status: "pending",
        comments: "All required documents submitted for review.",
      };
  
      await setDoc(
        loanHistoryRef,
        {
          loanId,
          loanHistory: arrayUnion(historyEntry),
        },
        { merge: true } // Ensure merging with existing data
      );
  
      toast.success("Your documents have been submitted successfully.");
      setShowModal(false);
      console.log("Loan documents submitted and history created/updated.");
    } catch (error) {
      console.error("Error submitting loan documents or updating history:", error);
      alert("There was an error submitting your documents.");
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-4">Loan Program Search</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Select Client */}

        <label className="block text-sm font-medium text-gray-700">
          <select
            value={selectedClient ? selectedClient.id : ""}
            onChange={handleClientChange}
            className="border border-gray-300 w-full rounded-lg p-2"
          >
            <option value="">Select a Client</option>
            {clients.map((client) => (
              <option key={client.id} value={client.id}>
                {client.name}
              </option>
            ))}
          </select>
        </label>

        <div className="flex justify-between gap-6">
          {/* Basic Salary Input */}
          <div className="w-full">
            <label
              htmlFor="basicSalary"
              className="block text-sm font-medium text-gray-700"
            >
              Basic Salary
            </label>
            <input
              type="number"
              id="basicSalary"
              name="basicSalary"
              value={searchCriteria.basicSalary}
              onChange={handleChange}
              className="mt-1 p-2 border border-gray-300 rounded w-full"
              min="0"
              readOnly // Prevent direct edits
            />
          </div>

          {/* Financial Amount Input */}
          <div className="w-full">
            <label
              htmlFor="financialAmount"
              className="block text-sm font-medium text-gray-700"
            >
              Financial Amount
            </label>
            <input
              type="number"
              id="financialAmount"
              name="financialAmount"
              value={searchCriteria.financialAmount}
              onChange={handleChange}
              className="mt-1 p-2 border border-gray-300 rounded w-full"
              min="0"
            />
          </div>
        </div>

        <div className="flex justify-between gap-6">
          {/* Program Type Dropdown */}
          <div className="w-full">
            <label
              htmlFor="programType"
              className="block text-sm font-medium text-gray-700"
            >
              Program Type
            </label>
            <select
              id="programType"
              name="programType"
              value={searchCriteria.programType}
              onChange={handleChange}
              className="mt-1 p-2 border border-gray-300 rounded w-full"
            >
              <option value="">Select the programType</option>
              <option value="Personal Loan">Personal Loan</option>
              <option value="Home Loan">Commercial</option>
              <option value="Car Loan">Car Loan</option>
            </select>
          </div>

          {/* Duration Input */}
          <div className="w-full">
            <label
              htmlFor="duration"
              className="block text-sm font-medium text-gray-700"
            >
              Duration (in months)
            </label>
            <select
              id="duration"
              name="duration"
              value={searchCriteria.duration}
              onChange={handleChange}
              className="mt-1 p-2 border border-gray-300 rounded w-full"
            >
              <option value="12">12 months</option>
              <option value="24">24 months</option>
              <option value="36">36 months</option>
              <option value="48">48 months</option>
            </select>
          </div>
        </div>

        <button
          type="submit"
          className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6 my-4 "
        >
          Search Loan Programs
        </button>
      </form>

      {/* Display Error */}
      {error && <p className="text-red-500 mt-4">{error}</p>}

      {/* Display Loan Programs */}
      <div className="mt-6">
        <h3 className="text-lg font-semibold">Available Loan Programs</h3>
        {loanPrograms.length > 0 ? (
          <ul>
            {loanPrograms.map((program, index) => (
              <li
                key={index}
                className="mb-2 shadow-custom p-4  rounded"
              >
                <div className="font-semibold">
                  {program.baseFields.programName}
                </div>
                <div className="flex justify-between">
                  <div>
                    Min Salary:{" "}
                    {program.baseFields.eligibilityCriteria?.minSalary || "N/A"}
                  </div>
                  <div>
                    Max Loan Amount:{" "}
                    {program.baseFields.loanDetails?.maxLoanAmount || "N/A"}
                  </div>
                  <div>
                    Duration:{" "}
                    {program.baseFields.loanDetails?.tenure.join(", ") || "N/A"}
                  </div>

                  <div>
                    {/* Apply Button */}
                    <button
                      onClick={() => handleApply(program)}
                      className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6 "
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No loan programs match your criteria.</p>
        )}
      </div>
      {/* Modal for Document Upload */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/2">
            <h3 className="text-lg font-semibold mb-4">
              Upload Loan Documents
            </h3>
            <label className="capitalize">Bank Statement</label>
            <input
              type="file"
              name="bank_statement"
              onChange={handleDocumentChange}
              className="file:bg-primaryColor file:px-6 file:rounded file:border-none file:text-white
              file:shadow-lg file:shadow-lightGray file:m-2 w-full rounded my-2
              bg-lightGray
               file:py-1"
            />

            <label className=" capitalize">DBR Ratio</label>
            <input
              type="file"
              name="dbr_ratio"
              onChange={handleDocumentChange}
              className="file:bg-primaryColor file:px-6 file:rounded file:border-none file:text-white
              file:shadow-lg file:shadow-lightGray file:m-2 w-full rounded my-2
              bg-lightGray
               file:py-1"
            />
            <label className="capitalize">Salary Definition</label>
            <input
              type="file"
              name="salary_definition"
              onChange={handleDocumentChange}
              className="file:bg-primaryColor file:px-6 file:rounded file:border-none file:text-white
              file:shadow-lg file:shadow-lightGray file:m-2 w-full rounded my-2
              bg-lightGray
               file:py-1"
            />

            <div className="flex justify-between mt-4">
              <button
                onClick={() => setShowModal(false)}
                className="bg-gray-500 text-white rounded-lg py-2 px-6"
              >
                Close
              </button>
              <button
                onClick={handleSubmitLoanDocuments}
                className="bg-gradient-to-r from-secondaryColor to-primaryColor text-white rounded-lg py-2 px-6"
              >
                Submit Documents
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FProgram;
