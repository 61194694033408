// InputField.jsx
import React from "react";

const InputField = ({ label, error,onChange, register, name, type = "text" }) => (
  <div>
    <label>{label}</label>
    <div>
      <input
        {...(register ? register(name) : {})}
        type={type}
        onChange={onChange}
        className="rounded-md w-full px-2 cursor-pointer py-1.5 shadow border-[1px] border-neutral-300"
      />
    </div>
    {error && <p style={{ color: "red" }}>{error}</p>}
  </div>
);

export default InputField;
