import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

export const fetchPermissions = createAsyncThunk(
  "permissions/fetchPermissions",
  async (roleId) => {
    if (!roleId) throw new Error("Role ID is required");

    const roleDocRef = doc(db, "roles", roleId);
    const roleDoc = await getDoc(roleDocRef);

    if (!roleDoc.exists()) {
      throw new Error("Role not found");
    }

    const { permissions } = roleDoc.data();
    return permissions; // Return permissions array
  }
);


const permissionsSlice = createSlice({
  name: "permissions",
  initialState: {
    permissions: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPermissions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPermissions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.permissions = action.payload; // Store permissions in state
      })
      .addCase(fetchPermissions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default permissionsSlice.reducer;


