import React, { useEffect, useState } from 'react';
import { collection, getDocs } from "firebase/firestore";
import { DataGrid } from "@mui/x-data-grid";
import { db } from "../../firebase";
import { Link } from 'react-router-dom';
import { FaPencilAlt, FaTrash } from 'react-icons/fa';

const LoanHistory = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLoanApplications = async () => {
      try {
        const appliedLoanRef = collection(db, "LoanHistories");
        const querySnapshot = await getDocs(appliedLoanRef);
        const loanData = querySnapshot.docs.flatMap((doc, index) => {
          const loanId = doc.id;
          const loanHistory = doc.data().loanHistory || [];

          // Spread loanHistory into rows with unique serial numbers
          return loanHistory.map((entry, historyIndex) => ({
            serialNo: `${index + 1}.${historyIndex + 1}`, // Dynamic Serial No
            loanId,
            ...entry, // Include action, status, comments, and timestamp
          }));
        });

        setData(loanData);
        console.log("loanData", loanData);
      } catch (error) {
        setError("Error fetching loan applications. Please try again later.");
        console.error("Error fetching loan applications:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLoanApplications();
  }, []);

  const columns = [
    {
      field: "serialNo",
      headerName: "Serial No",
      flex: 0.5,
      headerClassName: "header",
    },
    {
      field: "loanId",
      headerName: "Loan ID",
      flex: 1,
      headerClassName: "header",
    },
  
    {
      field: "document_action",
      headerName: "Document Action",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "comments",
      headerName: "Comments",
      flex: 2,
      headerClassName: "header",
    },
    {
        field: "action",
        headerName: "Action",
        flex: 1,
        headerClassName: "header",
        renderCell: (params) => (
          <div className="flex items-center gap-2 h-full">
           
            <Link
              to={`/update-loan-history/${params.row.id}`}
              className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8"
            >
              <FaPencilAlt className="text-white cursor-pointer h-4 w-4" />
            </Link>
  
            {/* Delete Icon */}
            <div
              className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8 cursor-pointer"
            >
              <FaTrash className="text-white h-4 w-4" />
            </div>
          </div>
        ),
      },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <div className="flex justify-between items-center mb-4">
        <h1 className="font-bold text-xl">Loan History</h1>
      </div>

      {loading ? (
        <div className="loader-container">Loading...</div>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : data.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-[80vh]">
          <p className="text-gray-500">No data found</p>
        </div>
      ) : (
        <DataGrid
          rows={data}
          columns={columns}
          getRowId={(row) => `${row.loanId}-${row.timestamp}`}
          sx={{
            "& .header": {
              backgroundColor: "#214783",
              color: "#FFFFFF",
              fontWeight: "bold",
              fontSize: "16px",
            },
            "& .MuiDataGrid-cell": {
              backgroundColor: "#f4f4f4",
              color: "#000",
            },
          }}
        />
      )}
    </div>
  );
};

export default LoanHistory;
