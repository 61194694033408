import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  setDoc,
} from "firebase/firestore";
import { db } from "../../firebase";

const ViewSingleLoanApplication = () => {
  const { id } = useParams(); // Retrieve loanId from URL
  const [loanApplication, setLoanApplication] = useState(null);
  const [loanDocuments, setLoanDocuments] = useState(null);
  const [eligibilityCriteria, setEligibilityCriteria] = useState({
    dbrRatio: false,
    salaryDefinition: false,
    financialLimits: false,
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLoanData = async () => {
      try {
        // Fetch Loan Application Data
        const docRef = doc(db, "AppliedLoanApplication", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          console.log("Fetched Loan Application Data:", data);
          setLoanApplication(data);
        } else {
          setError("Loan application not found.");
        }

        // Fetch Loan Documents related to this Loan Application
        const loanDocsRef = collection(db, "LoanDocuments");
        const loanDocsQuery = query(loanDocsRef, where("loanId", "==", id));
        const loanDocsSnap = await getDocs(loanDocsQuery);

        if (!loanDocsSnap.empty) {
          const docsData = loanDocsSnap.docs.map((doc) => doc.data());
          console.log("Fetched Loan Documents:", docsData);
          setLoanDocuments(docsData);
        } else {
          setLoanDocuments([]);
        }
      } catch (err) {
        console.error("Error details:", err.message);
        setError("Error fetching loan application or documents.");
      } finally {
        setLoading(false);
      }
    };

    fetchLoanData();
  }, [id]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setEligibilityCriteria((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleUpdate = async () => {
    try {
      // Create LoanDetails document structure
      const loanDetailData = {
        loanProgram: loanApplication.loanProgram,
        bank: loanApplication.bank,
        eligibilityCriteria: eligibilityCriteria,
        loanDocumentsRef: "/LoanDocuments/loan_document_1", // Adjust with actual reference
        loanHistoriesRef: "/LoanHistories/loan_history_1", // Adjust with actual reference
      };

      // Set the document in the LoanDetails collection
      const loanDetailsRef = doc(db, "LoanDetails", id);
      await setDoc(loanDetailsRef, loanDetailData);

      console.log("Loan details updated successfully.");
      alert("Loan details have been updated successfully.");
    } catch (error) {
      console.error("Error updating loan details:", error);
      alert("There was an error updating the loan details.");
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold">Loan Application Details</h1>
      {loanApplication ? (
        <div className="mt-4 mb-2 shadow-custom p-4 rounded ">
          <div className="flex justify-between">
            <p>
              <strong>Loan Program:</strong> {loanApplication.loanProgram}
            </p>
            <p>
              <strong>Bank:</strong> {loanApplication.bank}
            </p>
          </div>

          <div className="flex justify-between">
            <p>
              <strong>Loan Amount:</strong> {loanApplication.loanAmount}
            </p>
            <p>
              <strong>Status:</strong> {loanApplication.approvalStatus}
            </p>
          </div>
          {/* Add more fields as required */}
        </div>
      ) : (
        <p>No data available.</p>
      )}

      <h2 className="mt-6 text-xl font-semibold">Loan Documents</h2>
      {loanDocuments ? (
        <div className="mt-4">
          {loanDocuments.length === 0 ? (
            <p>No documents found for this loan application.</p>
          ) : (
            loanDocuments.map((doc, index) => (
              <div key={index} className="mt-4 mb-2 shadow-custom p-4 rounded">
                <div className="flex justify-between">
                  <p>
                    <strong>Bank Statement Status:</strong>{" "}
                    {doc.documents.bank_statement.status}
                  </p>
                  <a
                    href={doc.documents.bank_statement.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500"
                  >
                    View Bank Statement
                  </a>
                </div>

                <div className="flex justify-between">
                  <p>
                    <strong>DBR Ratio Status:</strong>{" "}
                    {doc.documents.dbr_ratio.status}
                  </p>
                  <a
                    href={doc.documents.dbr_ratio.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500"
                  >
                    View DBR Ratio
                  </a>
                </div>
                <div className="flex justify-between">
                  <p>
                    <strong>Salary Definition Status:</strong>{" "}
                    {doc.documents.salary_definition.status}
                  </p>
                  <a
                    href={doc.documents.salary_definition.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500"
                  >
                    View Salary Definition
                  </a>
                </div>
              </div>
            ))
          )}
        </div>
      ) : (
        <p>Loading documents...</p>
      )}

      <h2 className="mt-6 text-xl font-semibold">Eligibility Criteria</h2>
      <div className="mt-4 flex justify-between">
        <label>
          <input
            type="checkbox"
            name="dbrRatio"
            checked={eligibilityCriteria.dbrRatio}
            onChange={handleCheckboxChange}
          />
          DBR Ratio
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            name="salaryDefinition"
            checked={eligibilityCriteria.salaryDefinition}
            onChange={handleCheckboxChange}
          />
          Salary Definition
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            name="financialLimits"
            checked={eligibilityCriteria.financialLimits}
            onChange={handleCheckboxChange}
          />
          Financial Limits
        </label>
      </div>

      <button
        onClick={handleUpdate}
        className="text-white bg-gradient-to-r  from-secondaryColor to-primaryColor rounded-lg py-2 mt-6 px-6 "
      >
        Update Loan Details
      </button>
    </div>
  );
};

export default ViewSingleLoanApplication;
