import React from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import InputField from "../../components/fields/TextInput";
import SelectField from "../../components/fields/SelectField";
import { z } from "zod";
import { db } from "../../firebase";
import { addDoc, collection } from "firebase/firestore";
import { toast } from "react-toastify";
import { logUserActivity } from "../../services/logUserActivity";
import { useNavigate } from "react-router-dom";

const clientSchema = z.object({
  name: z.string().min(1, { message: "Client Name is required" }).max(50),
  age: z.string().min(2, { message: "Age must be at least 18" }).max(100),
  employmentStatus: z.enum(["Employed", "Self-employed", "Unemployed"]),
  annualIncome: z
    .string()
    .min(1, { message: "Income must be a positive number" }),
  contact: z.string().min(10, { message: "Contact number is required" }),
  email: z.string().email({ message: "Invalid email address" }),
  existingLoans: z
    .string()
    .optional()
    .transform((val) =>
      val ? val.split(",").map((loanId) => loanId.trim()) : []
    ),
});

const AddClient = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(clientSchema),
  });

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const formattedData = {
        name: data.name,
        age: data.age,
        employmentStatus: data.employmentStatus,
        annualIncome: data.annualIncome,
        contact: data.contact,
        email: data.email,
        // existingLoans: data.existingLoans,
      };

      const docRef = await addDoc(collection(db, "Clients"), formattedData);
      console.log("Document written with ID: ", docRef.id);
      toast("Client added successfully!");
      logUserActivity("User Added Client");
      navigate("/clients");
    } catch (error) {
      console.error("Error adding client: ", error);
      toast.error("Failed to add client.");
    }
  };

  return (
    <>
      <div>
        <h1 className="font-bold text-xl">Create Client</h1>
      </div>

      <div className="rounded-lg shadow">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-[90%] mx-auto flex flex-col gap-4 p-10">
            <InputField
              label="Client Name"
              error={errors.name?.message}
              register={register}
              name="name"
            />
            <InputField
              label="Age"
              error={errors.age?.message}
              register={register}
              name="age"
              type="number"
            />
            <Controller
              control={control}
              name="employmentStatus"
              render={({ field }) => (
                <SelectField
                  label="Employment Status"
                  options={[
                    { id: "Employed", name: "Employed" },
                    { id: "Self-employed", name: "Self-employed" },
                    { id: "Unemployed", name: "Unemployed" },
                  ]}
                  register={register}
                  name="employmentStatus"
                  error={errors.employmentStatus?.message}
                  onChange={field.onChange}
                  disabled={false}
                />
              )}
            />
            <InputField
              label="Annual Income"
              error={errors.annualIncome?.message}
              register={register}
              name="annualIncome"
              type="number"
            />
            <InputField
              label="Contact Number"
              error={errors.contact?.message}
              register={register}
              name="contact"
              type="tel"
              placeholder="Enter contact number"
            />
            <InputField
              label="Email Address"
              error={errors.email?.message}
              register={register}
              name="email"
              type="email"
              placeholder="Enter email address"
            />
            {/* <InputField
              label="Existing Loans (Comma-separated IDs)"
              error={errors.existingLoans?.message}
              register={register}
              name="existingLoans"
              placeholder="Enter loan IDs separated by commas"
            /> */}

            <button
              type="submit"
              className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6 my-6"
            >
              Add Client
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddClient;
