import React, { useEffect, useState } from "react";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import InputField from "../../components/fields/TextInput";
import SelectField from "../../components/fields/SelectField";
import { db } from "../../firebase";
import { addDoc, collection, getDocs } from "firebase/firestore";
import { toast } from "react-toastify";
import { z } from "zod";
import { logUserActivity } from "../../services/logUserActivity";
import { useNavigate } from "react-router-dom";

const applicationSchema = z.object({
  
  clientId: z.string().min(1, { message: "Client ID is required" }),
  loanProgramId: z.string().min(1, { message: "Loan Program ID is required" }),
  bankId: z.string().min(1, { message: "Bank ID is required" }),
  status: z.enum(["Pending", "Approved", "Rejected"]),
  statusHistory: z
    .array(
      z.object({
        date: z.string().min(1, { message: "Date is required" }),
        status: z.string().min(1, { message: "Status is required" }),
        updatedBy: z
          .string()
          .min(1, { message: "Updated By field is required" }),
      })
    )
    .optional(),
});

const AddApplication = () => {
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [loanSchemes, setLoanSchemes] = useState([]);
  const [banks, setBanks] = useState([]);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(applicationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "statusHistory",
  });

  useEffect(() => {
    const fetchData = async () => {
      const clientsSnapshot = await getDocs(collection(db, "Clients"));
      const loanSchemesSnapshot = await getDocs(collection(db, "LoanProgram"));
      const banksSnapshot = await getDocs(collection(db, "Banks"));

      setClients(
        clientsSnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
        }))
      );
      setLoanSchemes(
        loanSchemesSnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().schemeName,
        }))
      );
      setBanks(
        banksSnapshot.docs.map((doc) => ({ id: doc.id, name: doc.data().name }))
      );
    };

    fetchData();
  }, []);

  const onSubmit = async (data) => {
    try {
      const formattedData = {
        clientId: data.clientId,
        loanProgramId: data.loanProgramId,
        bankId: data.bankId,
        status: data.status,
        statusHistory: data.statusHistory || [],
      };

      const docRef = await addDoc(
        collection(db, "Applications"),
        formattedData
      );
      console.log("Document written with ID: ", docRef.id);
      toast("Application added successfully!");
      logUserActivity("User Added Application");
      navigate("/applications");
    } catch (error) {
      console.error("Error adding application: ", error);
      toast.error("Failed to add application.");
    }
  };

  return (
    <>
    
      <div>
        <h1 className="font-bold text-xl">Create Application</h1>
      </div>

      <div className="rounded-lg shadow">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-[90%] mx-auto flex flex-col gap-4 p-10">
            <Controller
              control={control}
              name="clientId"
              render={({ field }) => (
                <SelectField
                  label="Client ID"
                  options={clients}
                  register={register}
                  name="clientId"
                  error={errors.clientId?.message}
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="loanProgramId"
              render={({ field }) => (
                <SelectField
                  label="Loan Program ID"
                  options={loanSchemes}
                  register={register}
                  name="loanProgramId"
                  error={errors.loanProgramId?.message}
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="bankId"
              render={({ field }) => (
                <SelectField
                  label="Bank ID"
                  options={banks}
                  register={register}
                  name="bankId"
                  error={errors.bankId?.message}
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="status"
              render={({ field }) => (
                <SelectField
                  label="Application Status"
                  options={[
                    { id: "Pending", name: "Pending" },
                    { id: "Approved", name: "Approved" },
                    { id: "Rejected", name: "Rejected" },
                  ]}
                  register={register}
                  name="status"
                  error={errors.status?.message}
                  onChange={field.onChange}
                />
              )}
            />

            {/* Status History Section */}
            <div>
              <div className="flex justify-between">
                <div>
                  <label className="font-medium">Status History</label>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() =>
                      append({ date: "", status: "", updatedBy: "" })
                    }
                    className="text-blue-500 mt-2"
                  >
                    Add Status History Entry
                  </button>
                </div>
              </div>
              {fields.map((item, index) => (
                <div key={item.id} className="flex gap-4">
                  <InputField
                    label="Date"
                    type="date"
                    {...register(`statusHistory.${index}.date`)}
                    error={errors.statusHistory?.[index]?.date?.message}
                  />
                  <InputField
                    label="Status"
                    {...register(`statusHistory.${index}.status`)}
                    error={errors.statusHistory?.[index]?.status?.message}
                  />
                  <InputField
                    label="Updated By"
                    {...register(`statusHistory.${index}.updatedBy`)}
                    error={errors.statusHistory?.[index]?.updatedBy?.message}
                  />
                  <button
                    type="button"
                    className="text-red-500"
                    onClick={() => remove(index)}
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>

            <button
              type="submit"
              className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6 my-6"
            >
              Add Application
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddApplication;
