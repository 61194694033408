import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import InputField from "../../components/fields/TextInput";
import { z } from "zod";
import { db } from "../../firebase";
import { addDoc, collection, doc, getDocs } from "firebase/firestore";
import { toast } from "react-toastify";
import { logUserActivity } from "../../services/logUserActivity";
import { useNavigate } from "react-router-dom";

// Define the schema for department input validation
const bankSchema = z.object({
  departmentName: z
    .string()
    .min(1, { message: "Department Name is required" })
    .max(30, { message: "Department Name cannot exceed 30 characters" }),
  departmentHead: z
    .string()
    .min(1, { message: "Department Head's Name is required" })
    .max(300, {
      message: "Department Head's Name cannot exceed 300 characters",
    }),
  contactNumber: z.string().min(1, { message: "Contact Number is required" }).max(15, { message: "Max 15 characters" }),
  departmentEmail: z
    .string()
    .email({ message: "Invalid email format" })
    .max(50, { message: "Department Contact Email cannot exceed 50 characters" }),
});

const AddDepartment = () => {
  const navigate = useNavigate();
  const [banks, setBanks] = useState([]); // Holds available banks
  const [branches, setBranches] = useState([]); // Holds branches of the selected bank
  const [selectedBank, setSelectedBank] = useState(""); // Holds selected bank ID
  const [selectedBranch, setSelectedBranch] = useState(""); // Holds selected branch ID

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(bankSchema),
  });

  // Fetch all banks and branches when the component mounts
  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const banksCollectionRef = collection(db, "Banks");
        const bankSnapshot = await getDocs(banksCollectionRef);
        const banksData = bankSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBanks(banksData); // Set fetched banks
      } catch (error) {
        console.error("Error fetching banks:", error);
      }
    };

    fetchBanks();
  }, []);

  // Fetch branches of the selected bank
  useEffect(() => {
    if (!selectedBank) {
      setBranches([]); // Reset branches if no bank is selected
      return;
    }

    const fetchBranches = async () => {
      try {
        const branchesRef = collection(db, "Banks", selectedBank, "Branches");
        const branchSnapshot = await getDocs(branchesRef);
        const branchData = branchSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBranches(branchData); // Set fetched branches
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    };

    fetchBranches();
  }, [selectedBank]); // Re-run when selected bank changes

  const onSubmit = async (data) => {
    const formattedData = {
      departmentName: data.departmentName,
      departmentHead: data.departmentHead,
      departmentEmail: data.departmentEmail,
      contactNumber: data.contactNumber,
    };

    if (!selectedBranch) {
      toast.warn("Please select a branch to add the department.");
      return;
    }

    try {
      const branchDocRef = doc(db, "Banks", selectedBank, "Branches", selectedBranch);
      const departmentsCollectionRef = collection(branchDocRef, "Departments");

      const docRef = await addDoc(departmentsCollectionRef, formattedData);
      console.log("Department document written with ID: ", docRef.id);
      toast.success("Successfully added department!");
      logUserActivity("User Added Department to Branch");
      navigate("/departments");
    } catch (error) {
      console.error("Error adding department document:", error);
      toast.error("Failed to add department.");
    }
  };

  return (
    <>
      <div>
        <h1 className="font-bold text-xl">Create Department</h1>
      </div>

      <div className="rounded-lg shadow">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-[90%] mx-auto flex flex-col gap-4 p-10">
            {/* Department Name */}
            <InputField
              label="Department Name"
              error={errors.departmentName?.message}
              register={register}
              name="departmentName"
            />

            {/* Department Head */}
            <InputField
              label="Department Head's Name"
              error={errors.departmentHead?.message}
              register={register}
              name="departmentHead"
            />

            {/* Contact Number */}
            <InputField
              label="Department Contact"
              error={errors.contactNumber?.message}
              register={register}
              name="contactNumber"
            />

            {/* Department Email */}
            <InputField
              label="Department Email"
              register={register}
              name="departmentEmail"
            />

            {/* Select Bank */}
            <label className="font-medium">Select Bank</label>
            <select
              value={selectedBank}
              onChange={(e) => setSelectedBank(e.target.value)}
              className="border border-gray-300 rounded-lg p-2"
            >
              <option value="">Select a Bank</option>
              {banks.map((bank) => (
                <option key={bank.id} value={bank.id}>
                  {bank.name} {/* Assuming the bank name is stored in bankName */}
                </option>
              ))}
            </select>

            {/* Select Branch */}
            <label className="font-medium">Select Branch</label>
            <select
              value={selectedBranch}
              onChange={(e) => setSelectedBranch(e.target.value)}
              className="border border-gray-300 rounded-lg p-2"
              disabled={!selectedBank} // Disable if no bank is selected
            >
              <option value="">Select a Branch</option>
              {branches.map((branch) => (
                <option key={branch.id} value={branch.id}>
                  {branch.branchname} - {branch.location}
                </option>
              ))}
            </select>

            {/* Submit Button */}
            <button
              type="submit"
              className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6 my-6"
            >
              Add Department
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddDepartment;
