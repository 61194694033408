import React from "react";

const MultiSelect = ({ label, options, name, register, error }) => {
  return (
    <div className="flex flex-col">
      <label htmlFor={name} className="text-gray-700 mb-2">
        {label}
      </label>
      <select
        id={name}
        {...register(name)}
        multiple
        className="border border-gray-300 rounded-md p-2 focus:ring-primaryColor focus:border-primaryColor"
      >
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
      {error && <p className="text-red-500 text-sm">{error}</p>}
    </div>
  );
};

export default MultiSelect;
