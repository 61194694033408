import { z } from "zod";

export const loanSchema = z.object({
  baseFields: z.object({
    programName: z.string().nonempty("Program Name is required"),
    selectedBank: z.string().nonempty("Bank selection is required"),
    eligibilityCriteria: z.object({
      minSalary: z.string().min(1, "Minimum Salary is required"),
      maxDBR: z.string().min(1, "Maximum DBR is required"),
      creditScore: z.string().min(3).max(300, "Valid Credit Score is required"),
      ageLimit: z.string().min(2).max(18, "Age Limit is required"),
    }),
    loanDetails: z.object({
      minLoanAmount: z.string().min(1, "Minimum Loan Amount is required"),
      maxLoanAmount: z.string().min(1, "Maximum Loan Amount is required"),
      interestRate: z.string().min(0, "Interest Rate is required"),
      tenure: z.array(z.string()).min(1, "At least one tenure is required"),
    }),
    requiredDocuments: z.array(z.string()).min(1, "At least one document is required"),
  }),
  programSpecificFields: z.object({
    prePaymentPenalty: z.boolean(),
    insuranceRequired: z.boolean(),
  }),
});
