import React from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { db } from "../../firebase";
import { addDoc, collection } from "firebase/firestore";
import { toast } from "react-toastify";
import InputField from "../../components/fields/TextInput";
import { logUserActivity } from "../../services/logUserActivity";
import { useNavigate } from "react-router-dom";

// Define the schema for loan scheme input validation
const loanSchemeSchema = z.object({
  loanType: z.string().min(1, { message: "Loan Type is required" }),
  schemeName: z.string().min(1, { message: "Scheme Name is required" }),
  criteria: z
    .array(
      z.object({
        description: z
          .string()
          .min(1, { message: "Criteria Description is required" }),
        minAmount: z
          .string()
          .min(0, { message: "Minimum amount must be 0 or more" }),
        maxAmount: z
          .string()
          .min(0, { message: "Maximum amount must be 0 or more" }),
        eligibilityAge: z
          .string()
          .min(2, { message: "Eligibility Age must be at least 18" }),
        employmentStatus: z
          .string()
          .min(1, { message: "Employment Status is required" }),
      })
    )
    .min(1, { message: "At least one criterion is required" }),
});

const AddLoanScheme = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(loanSchemeSchema),
  });

  const navigate = useNavigate();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "criteria",
  });

  const onSubmit = async (data) => {
    const formattedData = {
      loanType: data.loanType,
      schemeName: data.schemeName,
      criteria: data.criteria.map((criterion) => ({
        criterionId: Math.random().toString(36).substr(2, 9), // Generate unique criterion ID
        ...criterion,
      })),
    };

    try {
      const loanSchemesCollectionRef = collection(db, "LoanSchemes");
      await addDoc(loanSchemesCollectionRef, formattedData);
      toast.success("Successfully added loan scheme!");
      logUserActivity("User Added Loan Scheme");
      navigate("/loanschemes");
    } catch (error) {
      console.error("Error adding loan scheme:", error);
      toast.error("Failed to add loan scheme.");
    }
  };

  return (
    <>
      <div>
        <h1 className="font-bold text-xl">Create Loan Schema</h1>
      </div>

      <div className="rounded-lg shadow-lg">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-[90%] mx-auto flex flex-col gap-4 p-10">
            <InputField
              label="Loan Type"
              error={errors.loanType?.message}
              register={register}
              name="loanType"
            />
            <InputField
              label="Scheme Name"
              error={errors.schemeName?.message}
              register={register}
              name="schemeName"
            />

            {fields.map((item, index) => (
              <div key={item.id} className="border p-4 rounded-lg">
                <h3 className="text-lg font-semibold">Criteria {index + 1}</h3>

                <InputField
                  label="Criteria Description"
                  error={errors.criteria?.[index]?.description?.message}
                  register={register}
                  name={`criteria.${index}.description`}
                />
                <InputField
                  label="Minimum Amount"
                  type="number"
                  error={errors.criteria?.[index]?.minAmount?.message}
                  register={register}
                  name={`criteria.${index}.minAmount`}
                />
                <InputField
                  label="Maximum Amount"
                  type="number"
                  error={errors.criteria?.[index]?.maxAmount?.message}
                  register={register}
                  name={`criteria.${index}.maxAmount`}
                />
                <InputField
                  label="Eligibility Age"
                  type="number"
                  error={errors.criteria?.[index]?.eligibilityAge?.message}
                  register={register}
                  name={`criteria.${index}.eligibilityAge`}
                />
                <InputField
                  label="Employment Status"
                  error={errors.criteria?.[index]?.employmentStatus?.message}
                  register={register}
                  name={`criteria.${index}.employmentStatus`}
                />

                <button
                  type="button"
                  className="text-red-600"
                  onClick={() => remove(index)}
                >
                  Remove Criterion
                </button>
              </div>
            ))}

            <button
              type="button"
              className="text-blue-600"
              onClick={() =>
                append({
                  description: "",
                  minAmount: 0,
                  maxAmount: 0,
                  eligibilityAge: 18,
                  employmentStatus: "",
                })
              }
            >
              Add Criterion
            </button>

            <button
              type="submit"
              className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6 my-6"
            >
              Add Loan Scheme
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddLoanScheme;
