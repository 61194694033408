// src/utils/deviceUtils.js
export const getDeviceInfo = () => {
    const userAgent = navigator.userAgent;
    let deviceType = "Unknown Device";
    let os = "Unknown OS";
  
    // Determine device type
    if (/mobile/i.test(userAgent)) {
      deviceType = "Mobile Device";
    } else if (/tablet/i.test(userAgent)) {
      deviceType = "Tablet";
    } else if (/desktop/i.test(userAgent)) {
      deviceType = "Desktop";
    }
  
    // Determine operating system
    if (/win/i.test(userAgent)) {
      os = "Windows";
    } else if (/mac/i.test(userAgent)) {
      os = "MacOS";
    } else if (/linux/i.test(userAgent)) {
      os = "Linux";
    } else if (/android/i.test(userAgent)) {
      os = "Android";
    } else if (/ios/i.test(userAgent)) {
      os = "iOS";
    }
  
    return { deviceType, os };
  };
  